<template>
  <div class="content-box">
    <div class="titleBox">
      <img class="bgPic" src="../../assets/registeredAgent/registerPic.png" alt="" />
      <div class="title">
        <div class="main">Hello</div>
        <div class="sub">欢迎，注册快易刷展业版</div>
      </div>
    </div>
    <div class="form-box">
      <div class="recommendCodeBox">
        推荐码：<span v-if="recommendName">{{ "(" + recommendName + ")" }}</span>
        <span>{{ form.recommendCode }}</span>
      </div>
      <div class="item-box">
        <van-field :left-icon="phone" v-model="form.phone" type="digit" maxlength="11" placeholder="请输入登录手机号" />
      </div>
      <div class="item-box code">
        <van-field :left-icon="code" v-model.trim="form.smsCode" maxlength="4" placeholder="请输入验证码">
          <template #button>
            <van-button
              class="code-btn"
              size="small"
              @click="getVerifyCodeEvent"
              :loading="loadingCaptcha"
              loading-type="spinner"
              type="default"
              >{{ codeBtnText }}</van-button
            >
          </template>
        </van-field>
      </div>
      <div class="item-box">
        <van-field
          :left-icon="pwd"
          v-model.trim="form.password"
          :type="eyeShow ? 'text' : 'password'"
          clearable
          :right-icon="eyeShow ? eye2 : eye"
          @click-right-icon="rightIcon"
          placeholder="请设置登录密码"
        />
      </div>
      <div
        :class="['submit-btn', form.phone !== '' && form.smsCode !== '' && form.password !== '' ? 'activeBtn' : '']"
        @click="submit"
      >
        <van-loading v-if="loadingBtn" type="spinner" size="20px" class="btn-loading">立即注册</van-loading>
        <span v-else>立即注册</span>
      </div>
      <div class="checkbox-style">
        <van-checkbox v-model="checkboxValue" checked-color="#584B31" icon-size="16px">请阅读并勾选</van-checkbox
        ><span class="label" @click="webView(1)">《合伙人合作协议》</span>
        <span class="label" @click="webView(3)">《隐私政策》</span>
      </div>
    </div>

    <van-dialog
      v-model="captchaShow"
      title="图形验证码"
      show-cancel-button
      :before-close="captchaConfirm"
      @open="captchaOpen"
    >
      <div class="code-box">
        <div class="code-img" @click="captchaOpen">
          <img v-if="captchaUrl && !captchaError" :src="captchaUrl" alt="" />
          <div v-else class="replay-box"><van-icon name="replay" />&nbsp;点击重试</div>
        </div>
        <div class="code-input">
          <van-field v-model="captcha" maxlength="6" placeholder="请输入图形验证码" />
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { agentSms, agentAdd, getCaptcha } from "@/api/registeredAgent";
import { Toast } from "vant";
import { shareConfig } from "@/utils/share.js";

export default {
  data() {
    return {
      eye: require("../../assets/registeredAgent/eye.png"),
      eye2: require("../../assets/registeredAgent/eye2.png"),
      form: {
        recommendCode: this.$route.query.recommendCode,
        // recommendCode: "10003106",
        phone: "",
        smsCode: "",
        password: "",
      },
      recommendName: this.$route.query.recommendName,
      phone: require("../../assets/registeredAgent/phone.png"),
      code: require("../../assets/registeredAgent/code.png"),
      pwd: require("../../assets/registeredAgent/pwd.png"),
      comcode: require("../../assets/registeredAgent/comcode.png"),
      checkboxValue: false,
      codeBtnText: "获取验证码",
      codeBtnDisable: true,
      quickLink: "",
      title: "",
      isCaptcha: 0,
      captchaShow: false,
      captchaUrl: "",
      captcha: "",
      captchaKey: "",
      captchaError: false,
      eyeShow: false,
      loadingBtn: false,
      loadingCaptcha: false,
      title: "<快易刷展业版>快速注册",
      desc: "您的展业小管家，智能管理，数据尽在掌握。注册下载，启程全新商业之旅！",
      imgUrl: "http://oss-zhongys-dev.zfmgr.top/image/f7/60/76/1a/0a15029b3c804f70bcd7c5d6112721e0.png", //展业logo
    };
  },
  mounted() {
    console.log("this.form", this.form);
    // 提测时候记得打开
    shareConfig(this.title, this.desc, this.imgUrl);
  },
  methods: {
    rightIcon() {
      this.eyeShow = !this.eyeShow;
    },
    // 获取验证码效验
    getVerifyCodeEvent() {
      if (this.loadingCaptcha) {
        return;
      }
      if (!this.codeBtnDisable) {
        return;
      }
      let strTemp = /^1[3|4|5|6|7|8|9][0-9]{9}$/;

      if (!this.form.phone) {
        Toast({
          message: "手机号码不能为空",
          icon: "cross",
        });
        return false;
      }
      if (!strTemp.test(this.form.phone)) {
        Toast({
          message: "手机号码格式不正确",
          icon: "cross",
          duration: 2 * 1000,
        });
        return false;
      }
      if (this.isCaptcha === 1) {
        this.captchaShow = true;
      } else {
        let params = {
          phone: this.form.phone,
          type: 0,
        };
        this.sendRequestCode(params);
      }
    },
    // 打开弹窗调取图形验证码接口
    captchaOpen() {
      getCaptcha()
        .then((res) => {
          this.captchaError = false;
          this.captchaUrl = res.captcha;
          this.captchaKey = res.captchaKey;
        })
        .catch((err) => {
          this.captchaError = true;
          this.captchaUrl = "";
          this.captchaKey = "";
        });
    },
    // 弹窗获取短息验证码
    captchaConfirm(action, done) {
      if (action === "confirm") {
        if (this.captcha == "") {
          Toast({
            message: "请输入图形验证码",
            duration: 2 * 1000,
            icon: "cross",
          });
          done(false);
          return;
        }
        let params = {
          phone: this.form.phone,
          captcha: this.captcha,
          captchaKey: this.captchaKey,
        };
        this.sendRequestCode(params);
        done();
        this.captcha = "";
      } else {
        done();
        this.captcha = "";
      }
    },
    // 获取验证码接口
    sendRequestCode(data) {
      this.loadingCaptcha = true;
      agentSms(data)
        .then((res) => {
          this.loadingCaptcha = false;
          this.isCaptcha = res;
          let time = 60,
            i = 0;
          this.codeBtnText = --time + "s后重新获取";
          let timer = setInterval(() => {
            this.codeBtnText = --time + "s后重新获取";
            i++;
            if (i == 1) {
              this.codeBtnDisable = false;
            }
          }, 1000);
          setTimeout(() => {
            this.codeBtnText = "获取验证码";
            this.codeBtnDisable = true;
            clearInterval(timer);
          }, time * 1000);
        })
        .catch((err) => {
          this.loadingCaptcha = false;
          if (err.code === 5001) {
            this.isCaptcha = 1;
            this.captchaShow = true;
          } else {
            Toast({
              message: err.message,
              icon: "cross",
            });
          }
        });
    },
    // 立即注册
    submit() {
      let data = this.form;
      if (this.loadingBtn || !data.phone || !data.smsCode || !data.password) {
        return;
      }
      let strTemp = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
      if (!strTemp.test(this.form.phone)) {
        Toast({
          message: "手机号码格式不正确",
          icon: "cross",
          duration: 2 * 1000,
        });
        return false;
      }

      if (this.form.password.length < 6 || this.form.password.length > 15) {
        Toast({
          message: "请输入6-15位密码",
          duration: 2 * 1000,
          icon: "cross",
        });
        return;
      }
      let pwdTest = /^[0-9a-zA-Z]*$/g;
      if (!pwdTest.test(this.form.password)) {
        Toast({
          message: "请输入数字、字母、字母+数字组合的密码",
          duration: 2 * 1000,
          icon: "cross",
        });
        return;
      }
      if (!this.checkboxValue) {
        Toast({
          message: "请阅读并勾选协议",
          duration: 2 * 1000,
          icon: "cross",
        });
        return;
      }

      this.loadingBtn = true;
      this.form.account = this.form.phone;
      let params = JSON.parse(JSON.stringify(this.form));
      // params.password = sha1(params.password);
      agentAdd(params)
        .then((res) => {
          Toast("注册成功");
          this.loadingBtn = false;
          this.$router.push("copdownload");
        })
        .catch((err) => {
          this.loadingBtn = false;

          Toast({
            message: err.message,
            icon: "cross",
          });
        });
    },
    webView(type) {
      if (type) {
        if (type === 1) {
          window.location.href = "https://www.zhongyishua.com/protocol/agencyCooperationAgreement.html";
        } else if (type === 2) {
          window.location.href = "https://www.zhongyishua.com/protocol/agentManagementSpecification.html";
        } else if (type === 3) {
          window.location.href = "https://www.zhongyishua.com/protocol/agentPrivacyAgreement.html";
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .van-button--default {
  border: 1px solid #fff3f2;
  color: #865d14;
  border-radius: 10px;
}
/deep/.van-cell {
  background-color: transparent;
  padding-left: 24px !important;
  padding-top: 28px !important;
}
/deep/ .van-field__label {
  width: 100px;
}
/deep/ .van-field__left-icon {
  margin-right: 40px;
}
/deep/ .van-field__left-icon::after {
  content: "";
  width: 1px;
  height: 43px;
  position: absolute;
  top: 30px;
  left: 84px;
  background: #e5e6eb;
}
/deep/ .van-field__left-icon .van-icon {
  font-size: 50px;
}
/deep/.van-field__right-icon .van-icon {
  margin-right: 10px;
}
.content-box {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #fff9ef;
  .titleBox {
    position: relative;
    height: 428px;
    .bgPic {
      position: absolute;
      width: 480px;
      height: 400px;
      right: 0;
      top: 27px;
    }
    .title {
      padding-top: 106px;
      padding-left: 42px;
      color: #333333;
      font-weight: 700;
      .main {
        font-size: 66px;
      }
      .sub {
        font-size: 30px;
        margin-top: 8px;
      }
    }
  }
  .form-box {
    padding: 51px 34px;
    width: 668px;
    margin: 0 auto;
    box-sizing: border-box;
    overflow: hidden;
    background: #fff;
    margin-top: -40px;
    border-radius: 30px;
    .recommendCodeBox {
      color: #584b31;
      font-weight: 700;
      font-size: 28px;
      margin-bottom: 45px;
    }
    .item-box {
      margin-bottom: 32px;
      background: #f5f5f7;
      height: 100px;
      border-radius: 16px;
      .label {
        font-size: 30px;
        color: #333;
        font-weight: bold;
      }
      .codeNum {
        font-size: 32px;
        font-weight: bold;
      }
      .code-btn {
        font-size: 26px;
        position: absolute;
        right: 16px;
        top: -10px;
      }
    }
    .submit-btn {
      margin: 62px auto 80px;
      width: 100%;
      height: 92px;
      line-height: 92px;
      text-align: center;
      background-color: #f9f2e5;
      color: #c5ad83;
      border-radius: 20px;
      font-size: 32px;
      font-weight: 500;
      .btn-loading {
        color: #ffffff;
        .van-loading__text {
          color: #ffffff;
          font-size: 32px;
        }
      }
      &.activeBtn {
        color: #584b31;
        background: #f3ddbd;
      }
    }
  }
  .checkbox-style {
    font-size: 24px;
    color: #a8a8a8;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .label {
      font-size: 24px;
      color: #584b31;
    }
  }
}
.code-box {
  margin: 20px 0 40px;
  display: flex;
  justify-content: space-between;
  .code-img {
    width: 238px;
    height: 86px;
    border: 1px solid #e8e8e8;
    .replay-box {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      color: #ff6b03;
    }
    > img {
      width: 100%;
      height: 100%;
    }
  }
  .code-input {
    width: 276px;
    padding-left: 20px;
    border: 1px solid #e8e8e8;
  }
}
</style>
